<template>
  <modal name="address-box" height="auto" @before-open="beforeOpen" @before-close="beforeClose" style="overflow: auto;">
    <div class="modal-close" @click="beforeClose">
      <i class="ion-android-close"></i>
    </div>
    <div id="add-address">
      <loading :show="loginLoading" />
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="login-form">
              <h4 class="login-title">{{ $i18n.Address.Address }}</h4>
              <div class="row">
                <div class="col-md-12 col-12">
                  <label>{{ $i18n.Address.th.Consignee }}</label>
                  <input type="text" v-model="datas.consignee" :placeholder="$i18n.Address.th.ConsigneePlaceholder" />
                </div>
                <div class="col-12 mb--20">
                  <label>{{ $i18n.Address.th.Phone_Number }}</label>
                  <input type="text" v-model="datas.phone" :placeholder="$i18n.Address.th.PhoneNumberPlaceHolder" />
                </div>
                <div class="col-12 mb--20 cascader">
                  <label>{{ $i18n.Address.th.Area }}</label>
                  <el-cascader
                    v-model="datas.id"
                    :props="{
                      value: 'areaId',
                      label: 'name',
                      children: 'sons'
                    }"
                    style="width:100%;margin-bottom: 15px;"
                    :options="areas"
                    placeholder="Elegir"
                  ></el-cascader>
                </div>
                <div class="col-12 mb--20">
                  <label>{{ $i18n.Address.th.Postal }}</label>
                  <input type="text" v-model="datas.postcode" :placeholder="$i18n.Address.th.PostalPlaceholder" />
                </div>

                <div class="col-12 mb--20">
                  <label>{{ $i18n.Address.th.Detailed_Address }}</label>
                  <textarea v-model="datas.detail" id="" cols="30" rows="3"></textarea>
                </div>
                <div class="col-12 mb--20">
                  <label>{{ $i18n.Address.th.SetDefault }}</label>
                  <el-checkbox v-model="datas.default">{{ $i18n.Address.th.SetDefault }}</el-checkbox>
                </div>
                <div class="col-md-12">
                  <button @click="onLogin" class="torress-login_btn">
                    {{ $i18n.Address.th.SAVE }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<style lang="less">
@border-color: #ebebeb;
@body-text_color: #333333;
@primary-color: #fed100;
@bg-white_color: #fff;
@bg-nobel_color: #999999;
@heading-color: #333;

.el-cascader-panel {
  max-height: 180px;
}
#add-address {
  .cascader {
    input {
      margin-bottom: 0;
    }
  }

  .myniceselect.nice-select {
    width: 100%;
    padding: 0 20px;
  }

  position: relative;

  .loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.57);
  }
}

.myinput {
  position: relative;
  display: flex;

  .getcode {
    margin-bottom: 15px;
    line-height: 45px;
    cursor: pointer;
    padding: 0 19px;
    background-color: @primary-color;
    color: @bg-white_color;
    flex-shrink: 0;
  }
}
</style>
<script>
import Vue from 'vue'
import loading from '@/views/public/loading.vue'
import { addAddress, getAreaList } from '@/api'
import { mapState } from 'vuex'
import { Cascader, Checkbox } from 'element-ui'
Vue.use(Cascader)
Vue.use(Checkbox)

export default {
  data() {
    return {
      datas: {
        id: []
      },
      loginLoading: false,
      size: ['中国', '日本', '美国', '非洲'],
      sizeIndex: 0
    }
  },
  props: {
    show: {
      default: false
    },
    item: {
      default() {
        return {}
      }
    }
  },
  components: {
    loading
  },
  watch: {
    item(e) {
      if (e && e.addressId) {
        this.datas = {
          postcode: e.postcode,
          addressId: e.addressId,
          consignee: e.man,
          default: true,
          detail: e.address,
          id: [e.provinceId, e.cityId],
          phone: e.phone
        }
      } else {
        this.datas = {
          id: []
        }
      }
    },
    show(e) {
      if (e) {
        this.$modal.show('address-box', { height: 'auto' })
      } else {
        this.$modal.hide('address-box')
        // this.$modal.hide('address-box', { height: 'auto' })
      }
    }
  },
  created() {

    if (this.areas.length < 1) {
          
      // http://olemart.didigo.es/api/area/list
      getAreaList().then(res => {
           
        if (res.data.data) {
          // let area=res.data.data
          // let arr=[]
          
          // let dizhi=area[7]
    
          // arr.push(dizhi)
          this.$store.dispatch('setAreas',res.data.data)
        }
       
      })
    }
  },
  computed: {
    ...mapState(['areas'])
  },
  methods: {
    openSelect(e) {
      let el = e.currentTarget
      el.classList.toggle('open')
    },
    changeSelect(idx) {
      this.sizeIndex = idx
    },
    onLogin() {
      if (!this.datas.phone) {
        return this.$toasted.error(this.$i18n.ERROR_PHONE)
      }
      if (!this.datas.consignee) {
        return this.$toasted.error(this.$i18n.Address.ErrorConsignee)
      }
      if (!this.datas.postcode) {
        return this.$toasted.error(this.$i18n.Address.ErrorPostcode)
      }

      let id = this.datas.id
      let len = id.length
      if (!id || !len) {
        return this.$toasted.error(this.$i18n.Address.ErrorArea)
      }
      if (!this.datas.detail) {
        return this.$toasted.error(this.$i18n.Address.ErrorAddress)
      }

      this.loginLoading = true
      let params = {
        provinceId: id[len - 1],
        man: this.datas.consignee,
        phone: this.datas.phone,
        address: this.datas.detail,
        postcode: this.datas.postcode,
        defaultFlag: ~~this.datas.default
      }
      if (this.datas.addressId) {
        params.addressId = this.datas.addressId
      }
      addAddress(params)
        .then(res => {
          this.loginLoading = false

          if (res.status === 200 && res.data) {
            if (res.data.status !== 'SUCCESSS') {
              // this.$store.dispatch('openLoginBox', false)
              // this.$store.dispatch('loginLoading', false)
              this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE)
              // 关闭窗口
            } else {
              this.$toasted.success(this.$i18n.Address.SuccessAddress)
              // 回调
              // this.$emit('addressOk', true)
              this.beforeClose(true)
            }
          } else {
            return this.$toasted.error(this.$i18n.ERROR_SERVER)
          }
        })
        .catch(_ => {
          this.loginLoading = false
        })
    },
    beforeOpen() {},
    beforeClose(data = false) {
      // 恢复默认值
      this.$emit('addressOk', data)
    }
  }
}
</script>
